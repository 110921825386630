
import React from "react"


import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeaderComponent from "../../components/page-header";
import BgImage from "../../images/pages/back-office.jpg"
import { Container } from "react-bootstrap";

const BackOfficePage = () => (
    <Layout >
        <div style={{
            backgroundImage: `url(${BgImage})` ,
            backgroundPosition:`bottom center`,
            backgroundSize:`cover`
        }}>


            <PageHeaderComponent breadcrumb="services" name="Back-Office outsourcing" desc="Back office outsourcing provides a vital link to businesses to provide them with more technical and robust manpower that will enable small businesses to immediately scale its operations without additional capital expenditures."/>
        </div>
        <SEO title="Back-Office" />
        <section className="page-section pt-0">
            <Container>
                <p className="secondary-font text-extra-large mb-5">Some of the back office jobs that are being outsourced are the following:</p>
                <div className="col-md-8 mb-5 text-large">
                    <hr className="my-5"/>
                    <h3>Data Entry Services</h3>
                    <hr className="my-5"/>
                    <h3>Data Management Services</h3>
                    <hr className="my-5"/>
                    <h3>Support Desks Services</h3>
                    <hr className="my-5"/>
                    <h3>Billing and Invoicing Services</h3>
                    <hr className="my-5"/>

                    <p>As a company, before you start back office outsourcing it is a must that you a have a solid plan about what your goals are. Is it scalable growth? Is it setting up a permanent team offshore? </p>
                    <p>Whatever your goals are it is important to partner with the right team that will help you achieve your objectives</p>

                </div>

            </Container>
        </section>
    </Layout>
)

export default BackOfficePage
